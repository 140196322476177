








































import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import Btn from '../../../Component/Misc/Btn/Btn.vue';
import ImageTag from '../../../Component/Misc/Image/ImageTag/ImageTag.vue';
import LinkWrap from '../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';
import TextMixin from '../../../Mixin/TextMixin';

export default {
    name: 'TeaserGrid',
    components: {LinkWrap, ImageTag, Btn},
    mixins: [TextMixin],
    props: {
        context: ContentElementContext
    },
    data()
    {
        return {
            teaserTextLength: 100
        };
    },
    computed: {
        sliderItems()
        {
            return this.context.data.get('items', []);
        }
    },
    methods: {
        limitTeaserText(text)
        {
            return text.length >= this.teaserTextLength ?
                this.truncate(this.stripTags(text), this.teaserTextLength) + '...' :
                this.stripTags(text);
        }
    }
};

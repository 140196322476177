var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teaserGrid" }, [
    _c(
      "div",
      { staticClass: "teaserGrid__container" },
      _vm._l(_vm.sliderItems, function (item, index) {
        return _c(
          "div",
          { key: "sliderItem-" + index, staticClass: "teaserGrid__item" },
          [
            _c(
              "link-wrap",
              { attrs: { link: item.slug } },
              [
                item.teaserImage
                  ? _c("image-tag", {
                      staticClass: "teaserGrid__itemImage",
                      attrs: { definition: "teaser", image: item.teaserImage },
                    })
                  : _c(
                      "span",
                      {
                        staticClass:
                          "teaserGrid__itemImage teaserGrid__itemImage--empty",
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "teaserGrid__itemImageGradient" },
                          [_vm._v(_vm._s(item.title))]
                        ),
                      ]
                    ),
                _vm._v(" "),
                _c("span", { staticClass: "teaserGrid__itemTitle" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "teaserGrid__itemText" }, [
                  _vm._v(_vm._s(_vm.limitTeaserText(item.teaserText))),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "teaserGrid__itemLink" }, [
                  _vm._v(_vm._s(_vm.$t("magazine.list.more")) + " "),
                ]),
              ],
              1
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }